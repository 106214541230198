import React, {useState} from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import './GradeCalculator.css';

const calculateGradeScore = (values) => {
  const n1 = values.n1 / 100 || 0;
  const n2 = values.n2 / 100 || 0;
  const n3 = values.n3 / 100 || 0;

  return (
    24 * Math.max(n1, n2) +
    28 * Math.max(n2, n3) +
    48 * Math.max(n3, 0.3 * n1 + 0.7 * n2)
  );
};

const gradeScoreToLetter = (gradeScore) => {
  if (gradeScore >= 100) return "A+";
  if (gradeScore >= 90) return "A";
  if (gradeScore >= 85) return "A-";
  if (gradeScore >= 80) return "B+";
  if (gradeScore >= 66) return "B";
  if (gradeScore >= 60) return "B-";
  if (gradeScore >= 54) return "C+";
  if (gradeScore >= 40) return "C";
  return "Below C";
};





const calculateMinGrades = (values) => {
  const currentGrade = calculateGradeScore(values);
  const currentLetterGrade = gradeScoreToLetter(currentGrade);

  const minGrades = {
    'A+': 100,
    'A': 90,
    'A-': 85,
    'B+': 80,
    'B': 66,
    'B-': 60,
    'C+': 54,
    'C': 40,
    'Below C': 0,
  };

  // Remove all keys that are lower than the current grade
  Object.keys(minGrades).forEach((key) => {
    if (minGrades[key] < currentGrade) {
      delete minGrades[key];
    } else {
      minGrades[key] = 1000000;
    }
  });
  let tempGrade, tempLetterGrade;
  // if n3 is null or 0 then lets calculate the minimum grades for n3
  if (values.n3 === null || values.n3 === 0) {
    for (let i = 155; i > 0; i--) {
      tempGrade = calculateGradeScore({n1 : values.n1, n2 : values.n2, n3 : i});
      tempLetterGrade = gradeScoreToLetter(tempGrade);
      // if tempGrade is lower than the minGrades[tempLetterGrade] then update the minGrades[tempLetterGrade]
      if (tempGrade < minGrades[tempLetterGrade]) {
        minGrades[tempLetterGrade] = i;
    }
  }
}

  return minGrades;
};




/**
 * A component that renders a grade box with a label and an input field for a grade value.
 * @param {Object} props - The component props.
 * @param {string} props.children - The label for the grade box.
 * @param {string} props.name - The name of the input field.
 * @param {string} props.placeholder - The placeholder text for the input field.
 * @param {string} props.maxLength - The maximum length of the input field.
 * @returns {JSX.Element} - The rendered component.
 */
const GradeBox = ({ children, name, placeholder, maxLength }) => {
  return (
    <div className="grade-box">
      <h3>{children}</h3>
      <Field
        type="number"
        name={name}
        placeholder={placeholder}
        min="0" max="200"
        maxLength={maxLength}
      />
    </div>
  );
};

const GradeCalculators = () => {
  let { classID } = useParams();
  const [grade, setGrade] = useState(0);
  const [rawScore, setRawScore] = useState(0);
  const [minGrades, setMinGrades] = useState({});

  const initialValues = {
    n1: localStorage.getItem("n1") || 0,
    n2: localStorage.getItem("n2") || 0,
    n3: localStorage.getItem("n3") || 0,
  };

  const validationSchema = Yup.object({
    n1: Yup.number().required("Required"),
    n2: Yup.number().required("Required"),
    n3: Yup.number().required("Required"),
  });

  return (
    <div className="grade-calculator" id="331">
      <h3>ClassID: {classID}</h3>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          setRawScore(calculateGradeScore(values));
          setGrade(gradeScoreToLetter(calculateGradeScore(values)));
          if (values.n3 === null || values.n3 === 0) setMinGrades(calculateMinGrades(values));
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <ul>
              <li>
                <GradeBox
                  name="n1"
                  placeholder="N1"
                  maxLength="3"
                >
                  Test 1
                </GradeBox>
                {errors.n1 && touched.n1 ? (
                  <div>{errors.n1}</div>
                ) : null}
              </li>
              <li>
                <GradeBox name="n2"
                  placeholder="N2"
                  maxLength="3"
                >
                  Test 2
                </GradeBox>
                {errors.n2 && touched.n2 ? (
                  <div>{errors.n2}</div>
                ) : null}
              </li>
              <li>
                <GradeBox
                  name="n3"
                  placeholder="N3"
                  maxLength="3"
                >
                  Final
                </GradeBox>
                {errors.n3 && touched.n3 ? (
                  <div>{errors.n3}</div>
                ) : null}
              </li>
            </ul>
            <button type="submit">Calculate Grade</button>
          </Form>
        )}
      </Formik>
      <div>
        {rawScore > 0 && (<h3>Current Grade: {grade} ({rawScore})</h3>)}
        { minGrades === {} && (<h3>Minumum Grades on Final for:</h3>)}
        <ul>
          {Object.keys(minGrades).map((key) => (
            <li key={key}>
              <h2>{key} : {minGrades[key]}</h2>
            </li>
          ))}
        </ul>
        </div>
    </div>
  );
};

export default GradeCalculators;
