import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import GradeCalculators from './components/GradeCalculators/GradeCalculators.js';
import './App.css';

const Bar = () => {
  return (
    <div id="bar">
      <h1>Grade Calculator</h1>
       {/* <a href="">
        <img src="https://github.githubassets.com/images/modules/logos_page/GitHub-Mark.png" alt="github logo" width="50" height="50"/>
      </a> */}
    </div>
  )


}

const ClassSelector = () => {

return (
  <div id="class-selector">
    <h2>Classes</h2>
      <ul>
        <li>
          <Link to="/331">CSCI 331: Database Systems</Link>
        </li>
        <li>
          <Link to="/320">CSCI 320: Theory of Computation</Link>
        </li>
    </ul>
  </div>
)

}



const App = () => {
  
  return (
    <div id="grade-calculator">
    <Router basename='/'>
      <Bar/>
      <ClassSelector/>
      <Routes>
        <Route path='/:classID' element={<GradeCalculators/>}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
